/* eslint-disable no-unused-expressions */
<template>
  <b-modal id="modal-categoriaTratamiento"
  title="Categoria Tratamiento"
  hide-footer
  >
    <form v-on:submit.prevent="agregarCategoria" class="">
      <div class="row mb-3">
          <div class="col-sm-7 offset-sm-1">
            <input type="text" class="form-control"
              v-model="nombreCategoria"
              placeholder="Ingrese una Categoria"
              :disabled="inputDisabled"
              autocomplete="off">
          </div>
          <div class="col-sm-3">
            <button class="btn btn-warning">
              Agregar
            </button>
          </div>
      </div>
    </form>
    <div class="row">
      <div class="col-sm-12">
        <b-table responsive
        class="table table-striped table-bordered border-0 table-hover"
        :fields="fieldsTabla"
        :items="listaCategoria">
        <template #cell(accion)="row">
          <div class="pt-2 text-center">
            <i class="nav-icon i-Close-Window text-danger font-weight-bold"
            style="cursor: pointer"
            @click="eliminarCategoria(row.item)"></i>
          </div>
        </template>
        <template #cell(numero)="row">
          <label style="cursor: pointer"
          class="col-form-label">
          {{ row.item.numero }}
          </label>
        </template>
        <template #cell(nombre)="row">
          <label @click="row.item.editable = true"
          v-show="!row.item.editable"
          style="cursor:pointer"
          class="col-form-label">
          {{ row.item.nombre }}
          </label>
          <input
          v-model="row.item.nombre"
          v-show="row.item.editable"
          :maxlength="maxLengthInput"
          @blur="actualizarCategoria(row.item);row.item.editable = false"
          type="text"
          class="form-control"
          >
        </template>
        </b-table>
      </div>
    </div>
  </b-modal>
</template>
<script>
import axios from '@/modules/common/axiosERP';
import { util } from '@/plugins/util';

export default {
  name: 'ModalCategoriaTratamiento',
  data() {
    return {
      fieldsTabla: [
        {
          key: 'accion', label: '', tdClass: 'py-1', thClass: 'border-bottom', thStyle: { width: '10%' },
        },
        {
          key: 'numero', label: 'Id', tdClass: 'py-1', thClass: 'border-bottom', thStyle: { width: '10%' },
        },
        {
          key: 'nombre', label: 'Nombre', tdClass: 'py-1', thClass: 'border-bottom', thStyle: { width: '80%' },
        },
      ],
      nombreCategoria: '',
      inputDisabled: false,
      listaCategoria: [],
      maxLengthInput: 191,
    };
  },
  created() {
    this.listarCategoriaTratamientos();
  },
  methods: {
    async listarCategoriaTratamientos() {
      try {
        const RESPONSE = await axios.get('clinic/tratamiento');
        const LISTA_TEMPORAL = RESPONSE.data.data;
        LISTA_TEMPORAL.forEach((item, index) => { LISTA_TEMPORAL[index].editable = false; });
        this.listaCategoria = LISTA_TEMPORAL;
      } catch (error) {
        util.showNotify(error.response.data.message, 'error');
      }
    },
    async crearCategoria() {
      const REQUEST = { nombre: this.nombreCategoria };
      let estado = false;
      try {
        const response = await axios.post('clinic/tratamiento/categoria', REQUEST);
        await this.listarCategoriaTratamientos();
        estado = true;
        util.showNotify(response.data.message, 'success');
      } catch (error) {
        const mensajes = error.response.data.errors.nombre;
        //
        mensajes.forEach((texto) => util.showNotify(texto, 'error'));
      }
      return estado;
    },
    agregarCategoria() {
      this.inputDisabled = true;
      if (this.crearCategoria()) {
        this.nombreCategoria = '';
      }
      this.inputDisabled = false;
    },
    async actualizarCategoria(item) {
      const REQUEST = { nombre: item.nombre };
      try {
        const RESPONSE = await axios.put(`/clinic/tratamiento/${item.id}`, REQUEST);
        util.showNotify(RESPONSE.data.message, 'success');
      } catch (error) {
        const mensajes = error.response.data.errors.nombre;
        //
        mensajes.forEach((texto) => util.showNotify(texto, 'error'));
        await this.listarCategoriaTratamientos();
      }
    },
    async eliminarCategoria(fila) {
      try {
        this.listaCategoria = this.listaCategoria.filter((item) => (
          //
          item.id !== fila.id
        ));
        const RESPONSE = await axios.delete(`/clinic/tratamiento/${fila.id}`);
        util.showNotify(RESPONSE.data.message, 'success');
      } catch (error) {
        util.showNotify(error.response.data.message, 'error');
        await this.listarCategoriaTratamientos();
      }
    },
  },
};
</script>
